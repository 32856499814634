import createEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';

import { Discount } from 'entities/discount/discount';
import { StoreSaleType } from 'entities/shop/sale-type';

type Data = Discount[];
type Params = {
  saleType?: StoreSaleType;
  platformOs: PlatformOs;
};

const NAME = '/Products/GetPromotionsList';
const GetPromoBannerListEndpoint = createEndpoint<Params, Data>(NAME);

export default GetPromoBannerListEndpoint;
export { GetPromoBannerListEndpoint };
