import Link from '@swe/shared/providers/router/link';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import styles from './styles.module.scss';

import { DiscountImage } from 'common/components/discount-image';
import { buildNamedId } from 'common/router/utils';

import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import { DiscountInfo } from 'domains/product/components/discount-card/components/discount-info';
import { CompilationType } from 'entities/common/compilation';
import { getBannerAlt } from 'entities/common/seo';
import { Discount } from 'entities/discount/discount';

type DiscountCardProps = Pick<
  Discount,
  'id' | 'name' | 'description' | 'mobileBannerUrl' | 'productImages' | 'scheduleDisplayText' | 'fulfilmentTypes'
> &
  ComponentHasClassName & {
    variant?: 'horizontal' | 'vertical';
    width: number;
    lazy?: boolean;
    link?: AbsoluteURL | null;
  };

const DiscountCard = ({
  className,
  id,
  name,
  mobileBannerUrl,
  productImages,
  description,
  variant = 'vertical',
  width,
  lazy,
  fulfilmentTypes,
  scheduleDisplayText,
  link: _link,
}: DiscountCardProps) => {
  const { buildCatalogLink } = useCatalogQuery();
  const discountLink = buildCatalogLink({
    subPath: [CompilationType.DISCOUNT, buildNamedId(name, id)],
    filters: null,
    searchTerm: null,
    page: null,
  });
  const link = _link === null ? null : _link ?? discountLink;
  const Component = link ? Link : 'div';

  return (
    <Component
      className={cx(styles.root, className, variant === 'horizontal' && styles[`_variant_${variant}`])}
      href={link!}
    >
      {variant === 'vertical' && (
        <DiscountImage
          className={styles.image}
          mobileBannerUrl={mobileBannerUrl}
          productImages={productImages}
          alt={getBannerAlt(name)}
          fit="cover"
          title={name}
          width={width}
          isMobile
          lazy={lazy}
        />
      )}
      <div className={styles.infoWrapper}>
        <h2 className={styles.title}>{name}</h2>
        <div className={styles.info}>
          {variant === 'horizontal' && description && <p className={styles.description}>{description}</p>}
          <DiscountInfo
            className={styles.date}
            schedule={scheduleDisplayText}
            fulfilmentTypes={fulfilmentTypes}
          />
          {/* 7 case Promo info on Deals page */}
          {/* availableFulfillmentTypes will change in Discount */}
        </div>
      </div>
    </Component>
  );
};

export { DiscountCard };
